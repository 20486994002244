<template>
  <div class="dashboard-content" data-app>
    <!-- Content -->
    <div class="card">
      <div class="filters" style="padding: 10px 10px 0px 10px">
        <h3 class="text-center">
          Datos<v-btn
            color="#003a56"
            dark
            class="mb-2"
            style="float: right"
            @click.prevent="handleCreate()"
            >Crear
          </v-btn>
        </h3>
      </div>
      <simple-list
        :title="''"
        :dense="true"
        :headers="headers"
        :items="items"
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        :loadingText="'Cargando registros'"
        :hideFooter="true"
        :multiSort="false"
        :loading="loading"
        :class="'mb-4'"
        :sortable="true"
        :page="page"
        :pages="pages"
        :showCreate="true"
        :showDelete="true"
        :orderOptions="orderOptions"
        @create:item="handleCreate()"
        @edit:item="handleEdit($event)"
        @delete:item="deleteItem($event)"
        @update:page="pagination($event)"
        @orderEvent="handleOrder($event)"
      />
    </div>
    <modal name="crudModal" height="auto" :width="'80%'" :adaptive="true">
      <div class="card" style="padding: 2rem">
        <h3 class="text-center" v-if="flow == 'create'" style="margin-bottom: 20px">
          Crear dato
        </h3>
        <h3 class="text-center" v-if="flow == 'edit'" style="margin-bottom: 20px">
          Editar dato
        </h3>

        <div class="row">
          <v-form ref="form" v-model="valid" style="width: 100%" class="row">
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-select
                :rules="[(v) => !!v || 'El tipo de empresa es requerido']"
                :items="selectCompanyType"
                :item-text="'name'"
                :item-value="'code'"
                v-model="companyType"
                label="Tipo de empresa"
                type="text"
              ></v-select
            ></v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-select
                :rules="[(v) => !!v || 'El tipo de dato es requerido']"
                :items="selectTypeData"
                :item-text="'name'"
                :item-value="'code'"
                v-model="type"
                label="Tipo de dato"
                type="text"
              ></v-select
            ></v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-text-field
                required
                :rules="descriptionRules"
                label="Descripción"
                v-model="description"
              ></v-text-field>
            </v-col>
            <v-col cols="12" lg="4" md="6" sm="12">
              <v-checkbox label="Dato multiple" v-model="selection"></v-checkbox>
            </v-col>
            <v-col cols="12">
              <div class="row">
                <v-btn
                  style="margin: auto; margin-top: 10px; color: #fff"
                  v-if="flow == 'create'"
                  :disabled="!valid"
                  color="#003a56"
                  @click="createItem()"
                >
                  Crear
                </v-btn>
                <v-btn
                  style="margin: auto; margin-top: 10px; color: #fff"
                  v-if="flow == 'edit'"
                  :disabled="!valid"
                  color="#003a56"
                  @click="saveItem()"
                >
                  Guardar
                </v-btn>

                <v-btn
                  style="margin: auto; margin-top: 10px; color: #fff"
                  color="#003a56"
                  @click="reset"
                >
                  Limpiar
                </v-btn>
                <v-btn
                  style="margin: auto; margin-top: 10px; color: #fff"
                  color="#003a56"
                  @click="$modal.hide('crudModal')"
                >
                  Cancelar
                </v-btn>
              </div>
            </v-col>
          </v-form>
        </div>
      </div>
      <v-spacer></v-spacer>
    </modal>
  </div>
</template>

<script>
  import SimpleList from 'Components/Listing/SimpleList.vue'
  export default {
    name: 'Data',
    components: {
      SimpleList,
    },
    data() {
      return {
        loading: false,
        headers: [
          { text: 'Tipo de empresa', value: 'companyTypeText' },
          { text: 'Descripción', value: 'description' },
          { text: 'Tipo de dato', value: 'typeText' },
          { text: 'Dato multiple', value: 'selectionableText' },
          { text: 'Acciones', value: 'actions' },
        ],
        items: [],
        page: 1,
        pages: 1,
        item: {},
        filterActivated: false,
        orderBy: { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
        orderOptions: [
          { id: 1, name: 'Recientes', order: 'created_at', by: 'desc' },
          { id: 2, name: 'Antiguos', order: 'created_at', by: 'asc' },
          { id: 3, name: 'Nombre', order: 'name', by: 'asc' },
          { id: 4, name: 'Última Edición', order: 'updated_at', by: 'desc' },
        ],
        filters: {
          search: '',
          role: null,
          active: null,
          countryId: null,
        },
        activeOptions: [
          { id: null, name: 'Todas' },
          { id: 1, name: 'Activos' },
          { id: 0, name: 'Inactivos' },
        ],
        totalItems: 0,
        itemsPerPage: 10,
        flow: '',
        companyType: 0,
        description: '',
        selection: '',
        type: '',
        selectTypeData: [
          { code: 10, name: 'ACTIVIDADES' },
          { code: 14, name: 'CONTRATACIONES' },
          { code: 2, name: 'DATOS DE LA EMPRESA FRANQUICIANTE' },
          { code: 3, name: 'DATOS OPERATIVOS' },
          { code: 11, name: 'EXPERIENCIA' },
          { code: 4, name: 'INFORMACIÓN ECONÓMICA' },
          { code: 13, name: 'INFORMACIÓN GENERAL' },
          { code: 9, name: 'INFORMACIÓN SOBRE UBICACIÓN Y LOCAL REQUERIDO' },
          { code: 1, name: 'OBJETIVO' },
          { code: 5, name: 'OTROS DATOS IMPORTANTES' },
          { code: 15, name: 'PRODUCTOS O SERVICIOS QUE OFRECEN' },
          { code: 6, name: 'RESEÑA' },
          { code: 12, name: 'SERVICIOS ESPECIALIZADOS' },
          { code: 8, name: 'TIPO DE FRANQUICIAS QUE OFRECEN' },
          { code: 7, name: 'UBICACIÓN Y DATOS DE CONTACTO' },
        ],
        selectCompanyType: [
          { code: 0, name: 'Franquicias' },
          { code: 1, name: 'Asociaciones de Franquicias' },
          { code: 2, name: 'Consultores' },
          { code: 3, name: 'Proveedores' },
          { code: 4, name: 'Oferta Inmobiliaria' },
          { code: 5, name: 'Negocios' },
        ],
        valid: true,
        descriptionRules: [
          (v) => !!v || 'La descripción es requerida',
          (v) => v.length <= 100 || 'La descripción debe poser menos de 100 caracteres',
        ],
      }
    },
    created() {
      this.getData()
    },
    methods: {
      handleOrder(orderBy) {
        this.orderBy = orderBy
        this.getData()
      },
      async getData() {
        this.loading = true
        let orderString = ''
        if (this.orderBy.order && this.orderBy.by) {
          orderString = '&order=' + this.orderBy.order + '&by=' + this.orderBy.by
        }
        axios
          .get(process.env.VUE_APP_API_DIRECTORY + 'data?page=' + this.page + orderString)
          .then((response) => {
            this.items = response.data.data
            if (this.items.length < 1) {
              this.loadingText = 'No hay registros'
            }
            this.totalItems = response.data.total
            this.itemsPerPage = response.data.per_page
            this.page = response.data.current_page
            this.pages = response.data.last_page
            this.items.map((x) => {
              let selectionableText
              switch (x.selection) {
                case 1:
                  selectionableText = 'Si'
                  break
                default:
                  selectionableText = 'No'
                  break
              }
              x.selectionableText = selectionableText
              let typeText
              switch (x.type) {
                case 2:
                  typeText = 'DATOS DE LA EMPRESA FRANQUICIANTE'
                  break
                case 3:
                  typeText = 'DATOS OPERATIVOS'
                  break
                case 4:
                  typeText = 'INFORMACIÓN ECONÓMICA'
                  break
                case 5:
                  typeText = 'OTROS DATOS IMPORTANTES'
                  break
                case 6:
                  typeText = 'RESEÑA'
                  break
                case 7:
                  typeText = 'UBICACIÓN Y DATOS DE CONTACTO'
                  break
                case 8:
                  typeText = 'TIPO DE FRANQUICIAS QUE OFRECEN'
                  break
                case 9:
                  typeText = 'INFORMACIÓN SOBRE UBICACIÓN Y LOCAL REQUERIDO'
                  break
                case 10:
                  typeText = 'ACTIVIDADES'
                  break
                case 11:
                  typeText = 'EXPERIENCIA'
                  break
                case 12:
                  typeText = 'SERVICIOS ESPECIALIZADOS'
                  break
                case 13:
                  typeText = 'INFORMACIÓN GENERAL'
                  break
                case 14:
                  typeText = 'CONTRATACIONES'
                  break
                case 15:
                  typeText = 'PRODUCTOS O SERVICIOS QUE OFRECEN'
                  break
                default:
                  typeText = ''
                  break
              }
              let companyTypeText
              switch (x.company_type) {
                case 0:
                  companyTypeText = 'Franquicias'
                  break
                case 1:
                  companyTypeText = 'Asociaciones de Franquicias'
                  break
                case 2:
                  companyTypeText = 'Consultores'
                  break
                case 3:
                  companyTypeText = 'Proveedores'
                  break
                case 4:
                  companyTypeText = 'Oferta Inmobiliaria'
                  break
                case 5:
                  companyTypeText = 'Negocios'
                  break
                default:
                  typeText = ''
                  break
              }
              x.companyTypeText = companyTypeText
              x.typeText = typeText
            })
            this.loading = false
          })
      },

      async handleCreate() {
        this.flow = 'create'
        this.item = {}
        this.companyType = 0
        this.description = ''
        this.type = ''
        this.selection = false
        this.$modal.show('crudModal')
      },

      async handleEdit(item) {
        this.flow = 'edit'
        this.item = item
        this.companyType = this.item.company_type
        this.description = this.item.description
        this.type = this.item.type
        this.selection = this.item.selection == '0' ? false : true
        this.$modal.show('crudModal')
      },

      validate() {
        this.$refs.form.validate()
      },

      reset() {
        this.$refs.form.reset()
      },

      async createItem() {
        this.validate()
        this.loading = true
        this.selection = this.selection ? 1 : 0
        axios
          .post(process.env.VUE_APP_API_DIRECTORY + 'data', {
            company_type: this.companyType,
            description: this.description,
            type: this.type,
            selection: this.selection,
          })
          .then((response) => {
            this.getData()
            this.$modal.hide('crudModal')
          })
          .catch((error) => {})
          .finally(() => (this.loading = false))
      },

      async saveItem() {
        this.validate()
        this.loading = true
        this.selection = this.selection ? 1 : 0
        axios
          .put(process.env.VUE_APP_API_DIRECTORY + 'data/' + this.item.id, {
            company_type: this.companyType,
            description: this.description,
            type: this.type,
            selection: this.selection,
          })
          .then((response) => {
            this.getData()
            this.$modal.hide('crudModal')
          })
          .catch((error) => {})
          .finally(() => (this.loading = false))
      },

      async deleteItem(item) {
        this.loading = true
        axios
          .delete(process.env.VUE_APP_API_DIRECTORY + 'data/' + item.id)
          .then((response) => {
            this.items = []
            this.getData()
          })
          .catch((error) => {})
          .finally(() => (this.loading = false))
      },

      async pagination(page) {
        this.page = page
        this.items = []
        await this.getData()
      },
    },
  }
</script>
<style lang="scss">
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 10px;
    transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  }
  .v-text-field__details {
    display: none;
    flex: 1 0 auto;
    max-width: 100%;
    min-height: 14px;
    overflow: hidden;
  }
  .col-lg-4,
  .col-md-6,
  .col-sm-12 {
    padding: 0px auto !important;
  }
  .theme--light.v-input {
    color: rgba(0, 0, 0, 0.87);
    padding: 0px;
    margin: 0px;
  }
  .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
    display: flex;
    flex: 1 1 auto;
    position: relative;
    width: 100%;
  }
</style>
